import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ba65e56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-password" }
const _hoisted_2 = ["type", "placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.inputType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
      placeholder: _ctx.placeholderText,
      readonly: _ctx.readonly,
      class: "form-control",
      autocomplete: "no-fields",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitPasswordChange && _ctx.emitPasswordChange(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, _ctx.password]
    ]),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.eyeIconClass),
        "aria-hidden": "true"
      }, null, 2)
    ])
  ]))
}