
import { Vue, Options } from "vue-class-component";
import {getProjectByIdRequest, saveObjektKennwortRequest} from "@/services/ObjectService";
import TogglePassword from '@/components/TogglePassword.vue';
import Swal from "sweetalert2";
@Options({
    components: {TogglePassword}
})
export default class ObjektKennwort extends Vue {
	showDropdown = false
	currentPassword = ""
    newPassword = ""
	
	get objectId() {
		return this.$route.params.id
	}

	get loggedInUser() {
		return this.$store.getters.user
	}

	handlePasswordChange(password: any) {
    	this.newPassword = password;
  	}
    
	shouldDisableInterventionChanges() {
		if (!this.loggedInUser.rights.interventions) {
			return true;
		}
		if (this.loggedInUser.rights.interventions) {
			return this.loggedInUser.rights.interventions.write
		}
	}

	saveObjektkennwort() {
		saveObjektKennwortRequest(this.newPassword, this.objectId).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Passwort erfolgreich geändert';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			if (res.status !== 'OK') return false
			Swal.fire({
				icon: icon,
				title: message,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary'
				}
			}).then(() => {
			// Reload the page after pressing "OK"
			window.location.reload();
			});
		
		})
	}
	
	beforeMount() {
		this.getProjectsByObject()
	}

	getProjectsByObject() {
		getProjectByIdRequest(this.objectId).then((res: any) => {
			if (res.status !== 'OK') return false
			this.currentPassword = res.result.details.objectkennwort
		})
	}
}
