
import {Options, Vue} from "vue-class-component";

@Options({
  name: 'TogglePassword',
  props: {
    placeholderText: {
      type: String,
      default: 'Enter your password'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String,
      default: ''
    }
  }
})
export default class TogglePassword extends Vue {
  password = (this.$props as any).defaultValue || '';
  showPassword = false;

  get inputType(): string {
    return this.showPassword ? 'text' : 'password';
  }

  get eyeIconClass(): string {
    return this.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye';
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  emitPasswordChange(): void {
    this.$emit('password-change', this.password);
  }
}
